// Setup for CustomAutocomplete is being ran at the bottom of the file
var CustomAutocomplete = {
    $: null,
    MaxItems: 5,
    ShowAllConditions: false,
    ShowAllProcedures: false,
    Data: null,
    TermId: '',
    resultsLoading: false,
    GetBaseSearchUrl: function() {
        return '/app/doctors/results.aspx?sort=7&page=1' + this.GetNavigationNodeQuery() + this.GetGeolocationQuery() + '&termId=';
    },
    GetNavigationNodeQuery: function() {
        return '&navigationNode=' + window.Geonetric.Page.NavigationNode;
    },
    GetGeolocationQuery: function() {
        // Get geolocation information from "position" cookie
        var cookie = $.cookie('position');
        if (!cookie) {
            return '';
        }
        var position = JSON.parse(cookie);
        // &userEnteredPosition=Cedar+Rapids%2c+IA+52403&lat=41.9781094&lng=-91.5886966
        var cityStateDisplay = [position.locality, position.administrative_area_level_1].filter(function(s) {
            return typeof s === 'string' && s.length > 0;
        }).join(', ');
        var userEnteredPosition = [cityStateDisplay, position.postal_code].filter(function(s) {
            return typeof s === 'number' || (typeof s === 'string' && s.length > 0);
        }).join(' ');
        var queryString = '';
        if (userEnteredPosition) { queryString += ('&userEnteredPosition=' + userEnteredPosition) }
        if (position.lat) { queryString += ('&lat=' + position.lat) }
        if (position.lng) { queryString += ('&lng=' + position.lng) }
        return queryString;
    },
    GetData: function() {
        var _$ = CustomAutocomplete.$;
        var outputFacetIds = ['5ae22862-8875-414c-8b59-77023ce78e97, 2c55e3b8-1b66-4096-a8dc-18406abc73fa'];
        var monroe = window.location.href.indexOf('/ehm/') > -1;
        var searchTerm = '&SearchTermIds=284ac3f2-b8cd-ea11-a83b-000d3a619f08'; //if on the monroe page we only want those providers with the "Monroe Provider - Yes" taxonomy term
        var url = '/nancy/taxonomy/FacetsAndObjectCount/viewmodel.json?ObjectTypeCode=5';
        monroe ? url += searchTerm: '';
        url += '&OutputFacetIds=' + outputFacetIds;
        var conditions = [];
        var procedures = [];

        return _$.getJSON(url, function(data) {
            data.Facets.forEach(function(facet) {
                if(facet.FacetPropertyName === 'ConditionsTreated') {
                    conditions = _$.map(facet.Terms, function(obj) {
                        return {
                            value: obj.Name,
                            id: obj.TermId,
                            type: 'Condition'
                        }
                    });
                } else {
                    procedures = _$.map(facet.Terms, function(obj) {
                        return {
                            value: obj.Name,
                            id: obj.TermId,
                            type: 'Procedure'
                        }
                    });
                }
            });
            CustomAutocomplete.Data = _$.merge(_$.merge([], conditions), procedures);
        });
    },
    FilterMenuItems: function(searchTerm) {
        var _$ = CustomAutocomplete.$;
        var pattern = new RegExp(_$.ui.autocomplete.escapeRegex(searchTerm.toLowerCase()));
        var filteredData = CustomAutocomplete.Data.filter(function(item) {
            return pattern.test(item.value.toLowerCase());
        });
        return filteredData;
    },
    SortMenuItems: function(items){
        return items.sort(function(a, b) {
            if (a.value.toLowerCase() < b.value.toLowerCase()){
                return -1;
            }
            if (b.value.toLowerCase() < a.value.toLowerCase()){
                return 1;
            }
            return 0;
        });
    },
    // We overwrite the function to get our own results because we may have partial lists or a full list. We have custom logic that isn't provided out of the box.
    GetMenuItems: function(request, callback) {
        var items = CustomAutocomplete.FilterMenuItems(request.term);
        var sortedItems = CustomAutocomplete.SortMenuItems(items, request.term);
        var conditions = sortedItems.filter(function(item) { return item.type === 'Condition' });
        var procedures = sortedItems.filter(function(item) { return item.type === 'Procedure' });

        if (CustomAutocomplete.ShowAllConditions){
            return callback(conditions);
        }
        else if (CustomAutocomplete.ShowAllProcedures){
            return callback(procedures);
        }

        var filteredConditions;
        if (conditions.length > CustomAutocomplete.MaxItems) {
            filteredConditions = conditions.slice(0, CustomAutocomplete.MaxItems);
            filteredConditions.push({
                label: "Show more conditions",
                value: "",
                type: "Condition",
                id: "",
                showMore: true
            });
        }

        var filteredProcedures;
        if (procedures.length > CustomAutocomplete.MaxItems) {
            filteredProcedures = procedures.slice(0, CustomAutocomplete.MaxItems);
            filteredProcedures.push({
                label: "Show more procedures",
                value: "",
                type: "Procedure",
                id: "",
                showMore: true
            });
        }
        return callback($.merge(filteredConditions ? filteredConditions : conditions, filteredProcedures ? filteredProcedures : procedures));
    },
    ConfigureWidget: function(){
        var _$ = CustomAutocomplete.$;
        var currentType = "";

        _$.widget( "custom.categoryComplete", _$.ui.autocomplete, {
            _create: function() {
                this._super();
                this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
            },
            _renderItem: function (ul, item) {
                if (item.type !== currentType) {
                    ul.append("<li class='ui-autocomplete-category'>" + item.type + "</li>");
                    currentType = item.type;
                }

                var li = _$('<li></li>')
                    .attr({
                        'data-type' : item.type,
                        'data-termId' : item.id
                    });

                if (item.showMore){
                    li.addClass('show-more');
                    li.append("<a>See more " + item.type.toLowerCase() + "s</a>");
                } else {
                    li.append('<div tabindex="-1" class="ui-menu-item-wrapper">' + item.value + '</div>')
                }

                ul.append(li);
                return li;
            },
            _renderMenu: function (ul, item) {
                currentType = "";
                this._super(ul, item);
            },
            _close: function (event) {
                if (event !== undefined && event.keepOpen === true){
                    CustomAutocomplete.TermId = '';
                    // Call the autocomplete widget 'search' method to force the
                    // window to reload new results.
                    this.search(null, event);
                    // Return true to exit method early BEFORE any logic to remove
                    // the autocomplete dropdown is called.
                    return true;
                }

                CustomAutocomplete.ShowAllConditions = false;
                CustomAutocomplete.ShowAllProcedures = false;

                if (CustomAutocomplete.TermId) {
                    this.resultsLoading = true;
                    window.location.assign(CustomAutocomplete.GetBaseSearchUrl() + CustomAutocomplete.TermId);
                }
                CustomAutocomplete.TermId = '';

                // This closes the window
                return this._super(event);
            }
        });
    },
    DrawWidget: function(){
        var _$ = CustomAutocomplete.$;
        _$("#PredictiveInput").categoryComplete({
            source: CustomAutocomplete.GetMenuItems,
            delay: 0,
            focus: function (event) {
                event.preventDefault();
            },
            select: function (event, ui) {
                var item = ui.item;
                if (item.showMore) {
                    if (item.type === 'Condition'){
                        CustomAutocomplete.ShowAllConditions = true;
                    }
                    else if (item.type === 'Procedure'){
                        CustomAutocomplete.ShowAllProcedures = true;
                    }

                    jQuery.extend(event.originalEvent,{ keepOpen:true });
                    return false;
                }
                if(item.id) {
                    CustomAutocomplete.TermId = item.id;
                }
            }
        })
        .keypress(function(event){
            // Prevent submit on "enter" keypress, submit should only be triggered by menu item selection
            if(event.keyCode == 13){
                event.preventDefault();
            }
        });
    },
    Setup: function(_$){
        CustomAutocomplete.$ = _$;

        // Create new search container.
        var html  = '<div id="PredictiveSearchContainer">';
        html += '<div id="PredictiveProcedureContainer">';
        html += '<label for="PredictiveInput">Type and select a Symptom or Procedure</label>';
        html += '<input type="text" name="predictive-input" id="PredictiveInput" maxlength="50" class="ui-autocomplete-input"/>';
        html += '<div id="PredictiveContainer">';
        html +=	'</div>';
        html += '</div>';
        html += '</div>';

        // Append new search container to existing container in place.
        /*if(_$('#ProviderAdvancedSearch').length) {
            _$('#ProviderAdvancedSearch').after(html);
        } else if(_$('#ConditionProcedureSearch').length) {
            _$('#ConditionProcedureSearch').after(html);
        }*/

        if(_$('#ConditionProcedureSearch').length) {
            _$('#ConditionProcedureSearch').append(html);
        }
        this.GetData().then(this.ConfigureWidget).then(this.DrawWidget);
    }
};

// Prevent Safari from submitting page on enter keypress after navigation to results has started
// Keyboard event listeners don't seem to catch "enter" after navigation has started
document.addEventListener('submit', function(e) {
    if (CustomAutocomplete && CustomAutocomplete.resultsLoading) {
        e.preventDefault();
    }
});

$(function() {
    CustomAutocomplete.Setup($);
});